import { useEffect, useState } from "react"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { Loader, useWindowDimensions } from "../../../GlobalFileContainer"
import { RiFileSearchLine } from "react-icons/ri"
import { FaCalculator } from "react-icons/fa"
import { SlChemistry } from "react-icons/sl"
import { VscJson } from "react-icons/vsc"
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { ReactComponent as ShareIcon } from "../../../assets/newIcons/Share.svg"
import { ReactComponent as BotHeaderIcon } from "../../../assets/chatboticons/bot-header.svg"
import {
  calculatorResultLoader,
  commonDateFormat,
  formatTimeBasedOnLocal,
  getEmulatorData,
  getEmulatorunch,
  jsonDialogOpenClose,
  populateResultData,
  siteWideRole,
  updateURLSearchParams,
} from "../../../state/projectState"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  ALGO_STATUS,
  emulatorMode,
  emulatorSoftwareType,
  FULL_VIEW_OPEN_TEXT,
} from "../../../state/StatusState"
import useStyles from "../../card_style"
import { renderIdenticalIcon } from "../../admin-dashboard/AgentHealthMain"
import { alertStates } from "../../../state/vizState"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { areObjectsEqual, isUserTeamMember } from "../../../state/services"
import { commonResultNumbering } from "../../shared components/CommonStyles/CommonStyles"
import { mongoProfileState } from "../../../state/userState"
import { useSearchParams } from "react-router-dom"

const InputOutputCard = ({
  index,
  name,
  data,
  version,
  width,
  id,
  status,
  showInputs,
  setShowInputs,
  smallWindow,
  lookup,
}) => {
  const classes = useStyles()
  const setAlertState = useSetRecoilState(alertStates)
  const emData = useRecoilValue(getEmulatorData)
  const mongoProfile = useRecoilValue(mongoProfileState)

  const isOutputs = name === "Outputs"
  const showVersion = (width < 480 || isOutputs) && (version === 0 || version)

  const InputToggleBox = ({ showInputs, setShowInputs }) => (
    <m.Button
      size="small"
      sx={{
        backgroundColor: "rgba(244,244,245,1)",
        color: "black",
        padding: "6px 16px",
      }}
      endIcon={
        showInputs ? (
          <VisibilityOffIcon fontSize="small" />
        ) : (
          <ArrowCircleRightOutlinedIcon fontSize="small" />
        )
      }
      onClick={() => setShowInputs(!showInputs)}
    >
      <m.Typography variant="subHeading" fontWeight="500">
        {showInputs ? "Hide Inputs" : "Show Inputs"}
      </m.Typography>
    </m.Button>
  )

  return (
    <core.Card
      key={index}
      className="width-100 ml-display-flex ml-flex-dir-col margin-right-5"
    >
      <core.Box
        className={`ml-display-flex ml-flex-dir-row ml-space-between ml-align-center`}
      >
        <core.Box
          style={{ width: "100%", flex: 1 }}
          className={`ml-display-flex ml-flex-dir-row ml-align-center tryit-${name}`}
        >
          <core.Typography
            variant="body1"
            className={`${classes.colorWhiteIcon} align-vertical`}
          >
            {name}
          </core.Typography>
          {showVersion && (
            <core.Typography
              variant="body1"
              className={`${classes.colorWhiteIcon} createEm-close-icon`}
            >
              (V{version})
            </core.Typography>
          )}
        </core.Box>
        {smallWindow && (
          <>
            {name === "Inputs" && lookup && (
              <InputToggleBox
                showInputs={showInputs}
                setShowInputs={setShowInputs}
              />
            )}
            {name === "Outputs" && (!showInputs || !lookup) && (
              <InputToggleBox
                showInputs={showInputs}
                setShowInputs={setShowInputs}
              />
            )}
          </>
        )}
      </core.Box>
      {isOutputs && (
        <>
          {id && !lookup && isUserTeamMember(mongoProfile, emData) && (
            <m.Typography
              variant="body1"
              className="outputscard-result ml-display-flex"
            >
              JobId-&nbsp;
              <p style={{ display: "flex", flexWrap: "wrap" }}>
                <b>
                  {renderIdenticalIcon(id, setAlertState, "Job ID", false, {
                    colDef: { width: 300 },
                    isCalcResult: true,
                  })}
                </b>
                &nbsp;
                {status === ALGO_STATUS.queued ||
                status === ALGO_STATUS.received ||
                status === ALGO_STATUS.incomplete
                  ? "in processing stage."
                  : ""}
              </p>
            </m.Typography>
          )}
        </>
      )}
      {data ? (
        <m.Typography variant="body1">{data}</m.Typography>
      ) : (
        <Loader margin={"0px"} />
      )}
    </core.Card>
  )
}

const returnIcon = (buttonName) => {
  switch (buttonName) {
    case emulatorMode.emulate:
      return <SlChemistry fontSize={30} />
    case emulatorMode.calculate:
      return <FaCalculator fontSize={20} />
    default:
      return <RiFileSearchLine fontSize={25} />
  }
}

const PrevNextButtons = ({
  handlePrev,
  handleNext,
  mobile,
  index,
  finalRes,
}) => {
  return (
    <>
      <m.IconButton
        title={"View previous result"}
        sx={{ color: "rgba(0, 0, 0, 0.73)" }}
        disabled={index === 0}
        onClick={handlePrev}
      >
        <ArrowBackIcon fontSize="small" />
      </m.IconButton>
      {!mobile && (
        <m.Box sx={{ width: "100%" }}>
          <m.Divider />
        </m.Box>
      )}
      <m.IconButton
        title={"View next result"}
        sx={{ color: "rgba(0, 0, 0, 0.73)" }}
        disabled={index === finalRes?.length - 1}
        onClick={handleNext}
      >
        <ArrowForwardIcon fontSize="small" />
      </m.IconButton>
      {!mobile && (
        <m.Box sx={{ width: "100%" }}>
          <m.Divider />
        </m.Box>
      )}
    </>
  )
}

const ReturnModeIcon = ({ resultItem, modeClickHandler }) => {
  return (
    <m.IconButton
      title="Put all the result inputs to input field"
      sx={{ color: "rgba(0, 0, 0, 0.73)" }}
      onClick={() => modeClickHandler(resultItem)}
    >
      {returnIcon(resultItem?.resultMode)}
    </m.IconButton>
  )
}

const OutputsCard = ({
  finalRes,
  currentIndex,
  fullView,
  threeRes,
  isModalObject,
  lookup,
  handlePrev,
  handleNext,
  updatedParsedData,
  consumer,
  setResults,
  showInputs,
  setShowInputs,
  setCurrentIndex,
  channelInstance,
}) => {
  const { width } = useWindowDimensions()
  const theme = m.useTheme()
  const [modeSearchParam] = useSearchParams()
  const [showCard1, setShowCard1] = useState([])
  const emulatorConfig = useRecoilValue(getEmulatorData)
  const setJsonDialogOpen = useSetRecoilState(jsonDialogOpenClose)
  const setPopulateResultDataState = useSetRecoilState(populateResultData)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const setAlertState = useSetRecoilState(alertStates)
  const [resultLoader, setResultsLoader] = useRecoilState(
    calculatorResultLoader
  )
  const launchData = useRecoilValue(getEmulatorunch)

  const launchID = modeSearchParam.get("launchid")

  const selectedCalcObject = launchData?.find(
    (launch) => launch.id === launchID
  )
  const calcConfig = launchID
    ? selectedCalcObject?.package.io
    : emulatorConfig?.calculator
  const searchParams = new URLSearchParams(window.location.search)
  const tryItMode = searchParams.get("mode")
  const smallWindow = fullView === FULL_VIEW_OPEN_TEXT

  const handleToggle = (index) => {
    const newToggleStates = [...showCard1]
    newToggleStates[index] = !newToggleStates[index]
    setShowCard1(newToggleStates)
  }

  const compareLabels = function (singleRd, expectedOutput, type) {
    return type === "output" &&
      channelInstance &&
      !channelInstance?.includes(emulatorSoftwareType.excel)
      ? channelInstance?.includes(emulatorSoftwareType.python)
        ? expectedOutput.PythonVariableName === singleRd
        : expectedOutput.ParamName === singleRd
      : expectedOutput.ColumnLabel === singleRd
  }
  
  const checkStringType = (str) => {
    const numberPattern = /^-?\d+(\.\d+)?$/
    const textPattern = /^[a-zA-Z0-9]+$/
    if (numberPattern.test(str)) {
      return "number"
    } else if (textPattern.test(str)) {
      return "string"
    } else {
      return "string"
    }
  }

  const dataToString = (data, unit) => {
    if (!Array.isArray(data) || data.length === 0) {
      return "No data available"
    }

    unit = unit ? unit.replace("none", "").trim() : ""

    const numbers = data.map((val) => {
      if (checkStringType(val) === "string") {
        return val
      } else {
        const valString = val.toString()
        const decimalIndex = valString.indexOf(".")
        const roundedNumber =
          decimalIndex !== -1 && valString.length - decimalIndex > 4
            ? Number(val).toFixed(3)
            : Number(val)
        const isWholeNumber = Math.floor(val) === val
        return `${
          isWholeNumber ? parseFloat(val.toFixed(10)) : roundedNumber
        } ${unit}`
      }
    })
    return numbers.join(", ")
  }

  const findMatchingInpOutputVar = (label, type) => {
    return (
      (lookup
        ? [
            ...(calcConfig?.InputVariables || []),
            ...(calcConfig?.OutputVariables || []),
          ]
        : type === "input"
        ? calcConfig?.InputVariables || []
        : calcConfig?.OutputVariables || []
      ).find((outputVar) => compareLabels(label, outputVar, type)) || {}
    )
  }

  const formatTimestamp = (value) => {
    const date = new Date(value * 1000)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = String(date.getSeconds()).padStart(2, "0")

    const timeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    const formattedTime = formatTimeBasedOnLocal(timeString, {
      ...commonDateFormat,
      year: "numeric",
      second: "2-digit",
    })

    return formattedTime
  }

  const convertOutputLabels = (element, type, otherProps) => {
    const elementName =
      type === "input"
        ? element?.Label
        : (lookup && otherProps?.resultMode === emulatorMode.lookup) ||
          channelInstance
        ? element?.name
        : element?.Ref?.Label

    const matchingOutVar = findMatchingInpOutputVar(elementName, type)
    const value = dataToString(
      type === "output" &&
        ((lookup && otherProps?.resultMode === emulatorMode.lookup) ||
          channelInstance)
        ? [element?.value]
        : element?.Data,
      matchingOutVar?.Units
    )
    return {
      name: matchingOutVar?.Name ?? element.name,
      value: element.name === "timestamp" ? formatTimestamp(value) : value,
    }
  }

  const lookupDisplayData = (output, otherProps, type) => {
    return output?.map((element, i) => {
      return (
        <>
          <m.Box
            sx={{
              backgroundColor: i % 2 !== 0 && theme.palette.secondary.faded,
              display: "flex",
              flexDirection: "row",
            }}
            key={`${type}-${i}`}
          >
            <m.Box sx={{ p: 1 }}>
              {commonResultNumbering(String.fromCharCode(65 + i))}
            </m.Box>
            <m.Divider sx={{ height: "inherit" }} orientation="vertical" />
            <m.Box>{displayData(element.Result, otherProps, type)}</m.Box>
          </m.Box>
          {i < output.length - 1 && <m.Divider />}
        </>
      )
    })
  }

  const displayData = (input, otherProps, type) => {
    return input?.map((element, i) => {
      const values =
        type === "Inputs"
          ? convertOutputLabels(element, "input")
          : otherProps.resultStatus === ALGO_STATUS.complete
          ? convertOutputLabels(element, "output", otherProps)
          : { name: element.name, value: element.value }
      return (
        <m.Typography
          className="outputscard-result"
          key={`index-${i}-${element.name}`}
        >
          <b>{`${values.name}: `}</b>
          {`${values.value}`}
        </m.Typography>
      )
    })
  }

  const deleteCalcHistory = (indexToDelete, emId) => {
    const handleDeleteHistory = () => {
      const decideKey = lookup && consumer ? "lookupHistory" : "socketJobs"
      const storedData = localStorage.getItem(decideKey)
      const parsedData = storedData ? JSON.parse(storedData) : []

      const filteredParsed =
        lookup && consumer
          ? parsedData.filter((parse) => parse.emulatorId === emId)
          : []

      const objectToDelete =
        lookup && consumer
          ? filteredParsed[filteredParsed.length - indexToDelete - 1]
          : updatedParsedData[updatedParsedData.length - indexToDelete - 1]

      setResults((prev) => prev?.filter((_, index) => index !== indexToDelete))
      if (indexToDelete === finalRes?.length - 1) {
        setCurrentIndex(indexToDelete - 1)
      }

      if (objectToDelete) {
        const originalIndex = parsedData.findIndex((obj) =>
          areObjectsEqual(obj, objectToDelete)
        )

        if (originalIndex !== -1) {
          parsedData.splice(originalIndex, 1)
        }

        localStorage.setItem(decideKey, JSON.stringify(parsedData))
      }
      setAlertState({
        boolState: true,
        message: "Result item deleted!",
        severityState: "info",
      })
    }

    return (
      <m.IconButton
        title="Delete result history"
        sx={{ color: "rgba(0, 0, 0, 0.73)" }}
        onClick={handleDeleteHistory}
      >
        <DeleteOutlineOutlinedIcon />
      </m.IconButton>
    )
  }

  const handleJsonViewerClick = (object) => {
    setJsonDialogOpen({
      state: true,
      obj: object,
    })
  }

  const modeClickHandler = (result) => {
    const outputObject = result.inputValues.reduce((acc, item) => {
      acc[item.Label] = item.Data[0].includes(" ")
        ? item.Data[0].split(" ")[1]
        : item.Data[0]
      return acc
    }, {})

    updateURLSearchParams(outputObject)
    setPopulateResultDataState(true)
  }

  const displayActionsOnCard = (mobile, index, resultItem) => {
    return (
      <div id="excludeElement">
        {mobile ? (
          <m.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {consumer && commonResultNumbering(index + 1)}
            <core.Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {smallWindow && (
                <PrevNextButtons
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                  mobile={true}
                  index={index}
                  finalRes={finalRes}
                />
              )}
              {consumer && (
                <>
                  {deleteCalcHistory(index, resultItem?.emulatorId)}
                  {!threeRes && (
                    <ReturnModeIcon
                      resultItem={resultItem}
                      modeClickHandler={modeClickHandler}
                    />
                  )}
                </>
              )}
              {width < 480 && (
                <core.FormControlLabel
                  value="top"
                  control={
                    <core.Switch
                      color="primary"
                      checked={showCard1[index]}
                      onChange={() => handleToggle(index)}
                    />
                  }
                  label={showCard1[index] ? "Inputs" : "Outputs"}
                  labelPlacement="start"
                />
              )}
            </core.Box>
          </m.Box>
        ) : (
          <m.Card
            sx={{ width: "50px", margin: "0 5px" }}
            className="ml-display-flex ml-flex-dir-col"
          >
            <m.Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              title={
                tryItMode !== emulatorMode.lookup
                  ? `${index + 1} of ${finalRes?.length} results`
                  : undefined
              }
            >
              {commonResultNumbering(index + 1)}
            </m.Box>
            <m.Box sx={{ width: "100%" }}>
              <m.Divider />
            </m.Box>
            {smallWindow && (
              <PrevNextButtons
                handleNext={handleNext}
                handlePrev={handlePrev}
                mobile={false}
                index={index}
                finalRes={finalRes}
              />
            )}
            {!lookup && (
              <>
                <m.IconButton>
                  <ShareIcon width={"20px"} height={"20px"} stroke="grey" />
                </m.IconButton>
                <m.Box sx={{ width: "100%" }}>
                  <m.Divider />
                </m.Box>
                {siteWideRoleState !== "consumer" &&
                  resultItem.resultMode === "calculate" && (
                    <m.IconButton
                      sx={{ color: "rgba(0, 0, 0, 0.73)" }}
                      title="Click to view job"
                    >
                      <VscJson
                        fontSize={"large"}
                        onClick={() => handleJsonViewerClick(finalRes[index])}
                      />
                    </m.IconButton>
                  )}
                {siteWideRoleState !== "consumer" && (
                  <m.Box sx={{ width: "100%" }}>
                    <m.Divider />
                  </m.Box>
                )}
              </>
            )}
            {consumer && (
              <>
                <ReturnModeIcon
                  resultItem={resultItem}
                  modeClickHandler={modeClickHandler}
                />
                <m.Box sx={{ width: "100%" }}>
                  <m.Divider />
                </m.Box>
              </>
            )}
            {deleteCalcHistory(index, resultItem?.emulatorId)}
            <m.Box sx={{ width: "100%" }}>
              <m.Divider />
            </m.Box>
          </m.Card>
        )}
      </div>
    )
  }

  const wholeCard = (index, resultItem) => {
    const filteredData = filterObjectForModal(resultItem)

    return (
      <m.Box
        sx={{
          display: "flex",
          flexDirection: lookup ? "column" : "row",
          width: "100%",
        }}
      >
        {/* {!threeRes && ( */}
        {displayActionsOnCard(lookup, index, resultItem)}
        {resultItem.resultType === "bot" && (
          <m.Typography className="outputscard-result">
            User inputs from AI chatbot <BotHeaderIcon fontSize="16px" />
          </m.Typography>
        )}
        <m.Box
          sx={{
            display: "flex",
            flexDirection: lookup ? "column" : "row",
            width: "100%",
          }}
        >
          {showInputs && (
            <InputOutputCard
              index={index}
              name={"Inputs"}
              version={resultItem.resultVersion}
              data={displayData(resultItem.inputValues, resultItem, "Inputs")}
              width={width}
              showInputs={showInputs}
              setShowInputs={setShowInputs}
              id={resultItem.jobId}
              status={resultItem.resultStatus}
              smallWindow={smallWindow}
              lookup={lookup}
            />
          )}
          <InputOutputCard
            index={index}
            name={"Outputs"}
            version={resultItem?.resultVersion}
            data={
              resultItem.resultMode === emulatorMode.lookup
                ? lookupDisplayData(
                    resultItem.outputValues,
                    resultItem,
                    "Outputs"
                  )
                : displayData(
                    isModalObject ? filteredData : resultItem.outputValues,
                    resultItem,
                    "Outputs"
                  )
            }
            width={width}
            id={resultItem.jobId}
            status={resultItem.resultStatus}
            showInputs={showInputs}
            setShowInputs={setShowInputs}
            smallWindow={smallWindow}
            lookup={lookup}
          />
        </m.Box>
      </m.Box>
    )
  }

  const filterObjectForModal = (resultItem) => {
    const updatedArray = resultItem?.outputValues?.filter((item) => {
      try {
        const parsedData = JSON.parse(item.Data[0])
        return typeof parsedData !== "object"
      } catch (error) {
        return true
      }
    })

    return updatedArray
  }

  const mobileViewCard = (index, resultItem) => {
    const filteredData = filterObjectForModal(resultItem)

    return (
      <m.Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {displayActionsOnCard(true, index, resultItem)}
        {showCard1[index] ? (
          <InputOutputCard
            index={index}
            name={"Inputs"}
            version={resultItem?.resultVersion}
            data={displayData(resultItem.inputValues, resultItem, "Inputs")}
            width={width}
            id={resultItem.jobId}
            status={resultItem.resultStatus}
            lookup={lookup}
          />
        ) : (
          <InputOutputCard
            index={index}
            name={"Outputs"}
            version={resultItem?.resultVersion}
            data={
              resultItem.resultMode === emulatorMode.lookup
                ? lookupDisplayData(
                    resultItem.outputValues,
                    resultItem,
                    "Outputs"
                  )
                : displayData(
                    isModalObject ? filteredData : resultItem.outputValues,
                    resultItem,
                    "Outputs"
                  )
            }
            width={width}
            id={resultItem.jobId}
            status={resultItem.resultStatus}
            showInputs={showInputs}
            setShowInputs={setShowInputs}
            lookup={lookup}
          />
        )}
      </m.Box>
    )
  }

  useEffect(() => {
    if (resultLoader) {
      setTimeout(() => {
        setResultsLoader(false)
      }, 3000)
    }
  }, [resultLoader])

  return (
    <>
      {resultLoader ? (
        <Loader />
      ) : (
        finalRes?.map((resultItem, index) => (
          <m.Grid
            key={`${index}-input/output`}
            className={`ml-display-flex ${
              threeRes ? "ml-flex-dir-col" : "ml-flex-dir-row"
            } my-result-card width-100 ${index !== 0 ? "grey-out" : ""}`}
          >
            {width > 480
              ? smallWindow
                ? index === currentIndex && wholeCard(index, resultItem)
                : wholeCard(index, resultItem)
              : smallWindow
              ? index === currentIndex && mobileViewCard(index, resultItem)
              : mobileViewCard(index, resultItem)}
          </m.Grid>
        ))
      )}
    </>
  )
}

export default OutputsCard
